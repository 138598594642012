import axios from 'axios';
import { getCookie } from './session';
import validation from './validation';
import Config from '../config/environments';
import { updateLastAPICallTime } from '../service/user';

const baseURL = Config.END_POINT;
const cookieAuth = getCookie(Config.INTEGRATION.AUTH_TOKEN_KEY);

const authBearer = cookieAuth && `Bearer ${cookieAuth}`;

const authBasic = `Basic ${Config.BASIC_AUTHORIZATION_KEY}`;

const baseRequest = {
  baseURL,
  withCredentials: true,
  timeout: 0,
  headers: {
    'Content-Type': 'application/json',
    Authorization: authBearer || authBasic
  }
};

const axiosLogin = axios.create(baseRequest);

const axiosInstance = axios.create(baseRequest);

const checkingInterceptor = (config) => {
  updateLastAPICallTime();
  return validation(config.response);
};

axiosInstance.interceptors.response.use(validation, checkingInterceptor);
axiosLogin.interceptors.response.use(validation, checkingInterceptor);

export { axiosInstance, axiosLogin };
