import {
  POST_CUSTOMER,
  LIST_CUSTOMER,
  FIND_CUSTOMER,
  FIND_CUSTOMER_BALANCE,
  FIND_BILLET_DETAIL,
  FIND_TRANSACTION_DETAIL,
  POST_STATUS,
  PUT_CUSTOMER,
  LIST_CUSTOMER_REASON
} from '../actionTypes/customer';

const BASE = {
  state: false,
  data: null
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: []
  }
};

const INITIAL_STATE = {
  statePostCustomer: BASE,
  listCustomer: BASE_PAGINATION,
  options: BASE,
  customerById: BASE,
  getBalanceByUuid: BASE,
  billetDetail: BASE,
  transactionDetails: BASE,
  statusNew: BASE,
  statePutCustomer: BASE
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case LIST_CUSTOMER_REASON:
      return { ...state, options: action.payload };
    case POST_CUSTOMER:
      return { ...state, statePostCustomer: action.payload };
    case PUT_CUSTOMER:
      return { ...state, statePutCustomer: action.payload };
    case LIST_CUSTOMER:
      return { ...state, listCustomer: action.payload };
    case FIND_CUSTOMER:
      return { ...state, customerById: action.payload };
    case FIND_CUSTOMER_BALANCE:
      return { ...state, getBalanceByUuid: action.payload };
    case FIND_BILLET_DETAIL:
      return { ...state, billetDetails: action.payload };
    case FIND_TRANSACTION_DETAIL:
      return { ...state, transactionDetails: action.payload };
    case POST_STATUS:
      return { ...state, statusNew: action.payload };
    default:
      return state;
  }
};
