import { LIST_SHOPPING } from '../actionTypes/shopping';

const BASE = {
  state: false,
  data: null
};

const INITIAL_STATE = {
  listShopping: BASE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_SHOPPING:
      return { ...state, listShopping: action.payload };
    default:
      return state;
  }
};
