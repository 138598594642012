export default {
  INTEGRATION: {
    AUTH_TOKEN_KEY: '@new-backoffice:auth_token',
    USER_INFO_KEY: '@new-backoffice:user_info'
  },
  APP_VERSION: process.env.REACT_APP_VERSION || 'Undefined',
  BASE_WEB_DOMAIN: process.env.REACT_APP_BASE_WEB_DOMAIN,
  BASE_WEB_NEW_URL: process.env.REACT_APP_BASE_WEB_NEW_URL,
  END_POINT: process.env.REACT_APP_API_BASE_URL || 'http://localhost:9010',
  RECAPTCHA_PUBLIC:
    process.env.REACT_APP_RECAPTCHA_PUBLIC || '6LfNZtkaAAAAAI5A0g_ZThtPllvGY6sbkQiuXf5Z',
  BASIC_AUTHORIZATION_KEY:
    process.env.REACT_APP_BASIC_AUTHORIZATION_KEY || 'YWJhc3RlY2VhaWFwaUBpcGlyYW5nYS5jb206MTIzNDU2',
  EXPIRATION_AUTH: process.env.MAX_IDLE_TIME || 3600000,
  EXPIRATION_FORM: 5, // Minuts
  DEBUG: {
    LEVEL: 'debug',
    AVAILABLE: true,
    LOCAL: true
  },
  USER: {
    BASE: '/user',
    BACKOFFICE: '/user/backoffice',
    URL: '/security/user',
    AUTH: '/auth/login',
    LOGOUT: '/auth/logout',
    CHANGE_STATUS: '/security/user/status',
    PERMISSIONS: '/backoffice/security/permission',
    PORTAL: '/store/user',
    RESEND_PASSWORD: '/new-password',
    GROUPS_AND_DEPARTMENTS: '/user/groups-and-departments'
  },
  CATEGORY: {
    URL: '/category'
  },
  INVOICE: {
    URL: '/invoice'
  },
  VOUCHERS: {
    URL: '/voucher'
  },
  CONTABIL: {
    URL: '/accounting-entry/cashback'
  },
  PERMISSION: {
    URL: '/security/permission'
  },
  GROUP: {
    URL: '/security/group'
  },
  PAYMENT: {
    URL: '/paymentmethod'
  },
  PAYMENT_OPTION: {
    URL: '/paymentmethod/options'
  },
  BANK: {
    URL: '/bank',
    ACTIVE: '/active'
  },
  BENEFIT: {
    URL: '/benefit',
    STATUS_BATCH: '/benefit/status/batch',
    UPDATE: '/draft/update'
  },
  SALES_CHANNEL: {
    LIST: '/sales-channel/list'
  },
  OPTIN: {
    URL: '/optin'
  },
  SCHEDULE: {
    // URL: '/topup/receivable',
    URL: '/receivable'
  },
  TRANSFER: {
    // URL: '/topup/transfer',
    URL: '/receivable/transfer',
    CONFIRM: '/receivable/reprocess',
    DOMICILE_BANKING: '/transfer/banking'
  },
  RECEIVABLE: {
    URL: '/receivable/',
    FILTER: '/receivable/filter',
    DOWNLOAD: '/receivable/download',
    INSTALLMENTS: '/receivable/installments',
    INSTALLMENTS_DOWNLOAD: '/receivable/installments/download',
    SCHEDULE_DOWNLOAD: '/receivable/schedule/download',
    SUMMARY: '/receivable/installments/abstract',
    RESUME_BY_DATE: '/receivable/summary',
    SPLIT_PROCESS: '/receivable/process/split',
    SPLIT_REPROCESS_STORE: '/receivable/reprocess/split',
    SPLIT_REPROCESS_MDR: '/receivable/reprocess/mdr',
    TED_PROCESS: '/receivable/process/ted',
    CASHOUT: '/receivable/process/cashout',
    SPLIT_ERROS: '/receivable/splitErrors',
    STATUS_REPORT: '/receivable/report/status',
    GENERATE_REPORT: '/receivable/report/generate',
    AVAILABLE: '/receivable/report/available',
    EXPORT_RECEIVABLE: '/receivable/report/export',
    APPROVE_REPORT: '/receivable/report/approve',
    SYNTHETIC: '/receivable/synthetic',
    SYNTHETIC_GRAPH: '/receivable/synthetic/status/graphic'
  },
  STORE: {
    URL: '/store',
    APPROVAL: '/store/approval',
    CREATE_INVOICE_XLS: '/store/invoice/loader/xls',
    CREATE_XLS: '/store/preregistration/xls',
    UPDATE_XLS: '/store/updateregistration/xls',
    DOWNLOAD_DOCUMENT: '/store/document',
    STORE_DOCUMENT_PAST: 'store-documents',
    STORE_UPLOAD_REPORT: '/store/update/report',
    STORE_UPLOAD_INVOICE_LOADER: '/store/invoice/loader',
    UPDATE_BRANCH_HEAD_OFFICE: '/store/update/branch-headoffice',
    BRANCHES: 'store/branches',
    OPERATIONAL_BALANCE: 'store/operational/balance',
    STORE_ORDER: '/store/store-order',
    STORE_AND_BRANCHES: '/store/stores-and-branches/user/',
    STORE_EMAIL: '/store/email/'
  },
  STORE_TYPE: {
    URL: '/store/type'
  },
  STORE_ACTIVATION: {
    URL: '/store/activation'
  },
  SHOPPING: {
    URL: '/shopping'
  },
  CUSTOMER: {
    URL: '/customer',
    REASON: '/customer/block/reason',
    URL_RELOAD_ACCOUNT: '/customer/reload_accounts_by_document',
    EXTRACT: {
      DOWNLOAD: 'customer/extract/download'
    },
    CREDIT_CARD: {
      URL: '/creditcard'
    },
    KMV: {
      URL: 'customer/kmv/information'
    },
    BALANCE: {
      URL: '/account/balance'
    },
    BILLET: {
      URL: '/eft/billet'
    },
    TED: {
      URL: '/eft/ted'
    },
    PIX: {
      URL: '/eft/pix'
    },
    P2P: {
      URL: '/eft/p2p'
    },
    TRUSTED: {
      URL: '/customer/trusted',
      REPORT: '/customer/trusted/report'
    },
    BLOCKLIST: {
      URL: '/customer/block',
      REPORT: '/customer/block/report'
    },
    BLOCKLIST_STORE: {
      URL: '/store/block',
      REPORT: '/store/block/report'
    },
    FACIAL_BIOMETRICS: {
      URL: '/customer/facial-biometrics',
      REPORT: '/customer/facial-biometrics/report'
    }
  },
  PIX: {
    URL: '/pix',
    CUSTOMER: '/pix/customer',
    STORE: '/pix/store',
    PIX_KEY: '/pix/key/'
  },
  POINT_SALE: {
    URL: '/store',
  },
  CANCEL_REASON: {
    URL: '/cancel-reason'
  },
  ORDER: {
    URL: '/order'
  },
  CONCESSION: {
    URL: '/concession',
    TRANSACTION: '/concession/transaction',
    CUSTOMER: '/concession/order/PF',
    STORE: '/concession/order/PJ'
  },
  MERCHANT: {
    URL: 'store/merchant',
    URL_ACCOUNT: 'store/merchant/account',
    URL_APPROVAL_ACCOUNT: 'store/merchant/account/approval'
  },
  DASHBOARD: {
    URL: '/store',
    TRANSACTION_INDICATORS: '/transaction/indicators',
    TRANSACTION_ABSTRACT: '/transaction/abstract',
    FUTURE_PAYMENTS: '/receivable/monthly',
    RECEIVABLES: '/receivable',
    STORES_BY_FILTER: '/store',
    DASHBOARD: '/dashboard',
    BALANCE: '/balance'
  },
  EXTRACT: {
    URL: '/customer',
    EXTRACT: '/extract'
  },
  ENRICH: {
    URL: '/store/merchant/account',
    APPROVAL: '/merchant/account/',
    NEW_URL:'/store/legal_person',
    NEW_URL_PDF:'/store/legal_person/pdf'
  },
  REPORT: {
    DOWNLOAD: '/report'
  },
  DOWNLOAD: {
    FILE: '/download/file'
  },
  PARTNER: {
    URL: '/partner'
  },
  PERFORMANCE: {
    URL: '/download/file'
  }
};
