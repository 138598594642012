export const POST_STORE = 'POST_STORE';
export const LIST_STORE = 'LIST_STORE';
export const GET_STORE = 'GET_STORE';
export const ORBITALL_ACCOUNT = 'ORBITALL_ACCOUNT';
export const APPROVAL_CREATE_ACCOUNT = 'APPROVAL_CREATE_ACCOUNT';
export const PATCH_ACTIVATION = 'PATCH_STORE';
export const LIST_ENRICH = 'LIST_ENRICH';
export const UPLOAD_STORES = 'UPLOAD_STORES';
export const UPDATE_STORES = 'UPDATE_STORES';
export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT';
export const LIST_UPLOAD_REPORT = 'LIST_UPLOAD_REPORT';
export const UPDATE_BRANCH_HEAD_OFFICE = 'UPDATE_BRANCH_HEAD_OFFICE';
export const GET_STORE_BRANCHES = 'GET_STORE_BRANCHES';
export const GET_STORE_OPERATIONAL_BALANCE = 'GET_STORE_OPERATIONAL_BALANCE';
