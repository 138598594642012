import React, { Fragment } from 'react';

function DefaultFooter() {
  return (
    <Fragment>
      <span>
        <a href="/">Backoffice Aí </a>
        &copy; 2020
      </span>
      <span className="ml-auto">
        Powered by
        <a href="https://www.protonss4fun.dev" target="_blank" rel="noopener noreferrer">
          {' '}
          P4F
        </a>
      </span>
    </Fragment>
  );
}

export default DefaultFooter;
