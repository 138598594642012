import { POST_SCHEDULE, LIST_SCHEDULE } from '../actionTypes/schedule';

const BASE = {
  state: false,
  data: null
};

const INITIAL_STATE = {
  statePostSchedule: BASE,
  listSchedule: BASE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_SCHEDULE:
      return { ...state, statePostSchedule: action.payload };
    case LIST_SCHEDULE:
      return { ...state, listSchedule: action.payload };
    default:
      return state;
  }
};
