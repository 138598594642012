export const POST_CUSTOMER = 'POST_CUSTOMER';
export const LIST_CUSTOMER = 'LIST_CUSTOMER';
export const FIND_CUSTOMER = 'FIND_CUSTOMER';
export const FIND_CUSTOMER_BALANCE = 'FIND_CUSTOMER_BALANCE';
export const FIND_BILLET_DETAIL = 'FIND_BILLET_DETAIL';
export const FIND_TRANSACTION_DETAIL = 'FIND_TRANSACTION_DETAIL';
export const PUT_CUSTOMER = 'PUT_CUSTOMER';
export const POST_STATUS = 'POST_STATUS';
export const ENABLE_CASHOUT = 'ENABLE_CASHOUT';
export const LIST_CUSTOMER_REASON = 'LIST_CUSTOMER_REASON';
