import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import {
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav
} from '@coreui/react';
import routes from '../routes/routes';
import navigation from '../routes/nav';
import DefaultFooter from './defaultFooter';
import DefaultHeader from './defaultHeader';
import PermissionDenied from '../views/pages/permission';
import { hasPermission } from '../service/user';
import Loading from '../views/utils/loading';

class DefaultLayout extends Component {
  constructor() {
    super();
    this.state = {};
    this.filterNavByPermission = this.filterNavByPermission.bind(this);
  }

  filterNavByPermission(navigationPrev) {
    const {
      userPermissions: { data: userPermissions }
    } = this.props;

    const filtredNavigation = navigationPrev.reduce((filtredNavAcc, filtredNav) => {
      const navChildrenFiltred = (filtredNav.children || []).filter(
        ({ permission }) =>
          (permission && hasPermission(permission, userPermissions)) || !permission
      );

      if (
        !!navChildrenFiltred.length ||
        (filtredNav.permission && hasPermission(filtredNav.permission, userPermissions)) ||
        (!filtredNav.permission && !filtredNav.children)
      ) {
        return [
          ...filtredNavAcc,
          { ...filtredNav, children: navChildrenFiltred.length ? navChildrenFiltred : null }
        ];
      }
      return filtredNavAcc;
    }, []);

    return { items: filtredNavigation };
  }

  render() {
    const {
      userPermissions: { data: userPermissions }
    } = this.props;

    if (!userPermissions || !userPermissions.length) return <Loading />;

    const appSidebarProps = { ...this.props };
    delete appSidebarProps.userPermissions;

    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav
              navConfig={this.filterNavByPermission(navigation)}
              {...appSidebarProps}
            />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes} />
            <Container fluid>
              <Switch>
                {routes.map((route) =>
                  route.component ? (
                    <Route
                      key={route.name}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={
                        !route.permission || hasPermission(route.permission, userPermissions)
                          ? (props) => <route.component {...props} />
                          : (props) => <PermissionDenied {...props} />
                      }
                    />
                  ) : null
                )}
                <Redirect from="/" to="/home" />
              </Switch>
            </Container>
          </main>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

DefaultLayout.propTypes = {
  userPermissions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const mapStateToProps = (state) => ({
  userPermissions: state.userState.userPermissions
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
