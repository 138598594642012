export default {
  ResponseStatus: '',
  Uuid: '',
  Email: '',
  Name: '',
  Type: 'BACKOFFICE',
  Document: '',
  Status: '',
  Password: '',
  Groups: [],
  ModalOpen: false
};

export const userDefaultFiltered = [
  { id: 'Email', value: '' },
  { id: 'Name', value: '' },
  { id: 'Uuid', value: '' },
  { id: 'Document', value: '' },
  { id: 'Type', value: '' },
  { id: 'Status', value: 'all' }
];
