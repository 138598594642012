import Model from '../model/user';
import { DocumentFormat } from '../lib/mask';

export const viewModelToModel = (view) => {
  try {
    if (!view) return null;

    const model = Object.assign({}, Model);

    model.ResponseStatus = view.responseStatus;
    model.Uuid = view.uuid;
    model.Email = view.email;
    model.Name = view.name;
    model.Type = view.type;
    model.Password = view.password;
    model.Document = view.document ? view.document.replace(/\D/g, '') : '';
    model.Status = view.status;
    model.Groups = view.groups;
    model.AuthToken = view.auth_token;
    model.Department = view?.department?.uuid;
    model.DepartmentName = view?.department?.name;

    return model;
  } catch (error) {
    throw new Error(`UserMapper - viewModelToModel = ${error}`);
  }
};

export const viewsModelToModels = (models, state) => {
  try {
    if (!models) return null;

    const views = [];

    models.users.forEach((model) => {
      const view = viewModelToModel(model);

      views.push(view);
    });

    const division = parseInt(models.total / state.PageSize, 10);
    const remainder = models.total % state.PageSize;

    return {
      total: division + (remainder <= 0 ? 0 : 1),
      list: views
    };
  } catch (error) {
    throw new Error(`UserMapper - viewsModelToModels = ${error}`);
  }
};

export const modelToViewModel = (model) => {
  try {
    if (!model) return null;

    const view = {};
    const groups = [];

    model.Checkboxes.forEach((obj) => {
      if (obj.Checked) {
        groups.push({ id: obj.Id });
      }
    });

    view.responseStatus = model.ResponseStatus;
    view.uuid = model.Uuid;
    view.email = model.Email;
    view.name = model.Name;
    view.type = model.Type;
    view.document = model.Document ? model.Document.replace(/\D/g, '') : '';
    view.status = model.Status;
    view.groups = groups;
    view.authorities = model.Authorities;
    view.department = model.Department;

    return view;
  } catch (error) {
    throw new Error(`UserMapper - ModelToViewModel = ${error}`);
  }
};

export const modelsToViewsModel = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.forEach(async (model) => {
      const view = modelToViewModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`UserMapper - ModelsToViewsModel = ${error}`);
  }
};

export const viewModelToState = (view) => {
  try {
    if (!view) return null;
    const model = Object.assign({}, Model);

    model.ResponseStatus = view.ResponseStatus;
    model.Uuid = view.Uuid;
    model.Email = view.Email;
    model.Name = view.Name;
    model.Type = view.Type;
    model.Password = view.Password || '';
    model.Document = DocumentFormat(view.Document);
    model.Status = view.Status;
    model.Groups = view.Groups;
    model.Authorities = view.Authorities;
    model.Department = view.Department;

    return model;
  } catch (error) {
    throw new Error(`UserMapper - viewModelToState = ${error}`);
  }
};

export const modelToAuthViewModel = (model, reCaptchaToken) => {
  try {
    if (!model) return null;

    const view = {};

    view.email = model.Email;
    view.password = model.Password;
    view.reCaptcha = reCaptchaToken;

    return view;
  } catch (error) {
    throw new Error(`UserMapper - modelToAuthViewModel = ${error}`);
  }
};

export const groupToCheckboxViewModel = (model, groups) => {
  try {
    if (!model) return null;

    const view = {};

    view.Id = model.Id;
    view.Name = model.Name;
    view.Description = model.Description;
    view.Checked = groups.some((g) => g.id === model.Id);

    return view;
  } catch (error) {
    throw new Error(`UserMapper - groupToCheckboxViewModel = ${error}`);
  }
};

export const groupsToCheckboxViewModel = (models, groups) => {
  try {
    if (!models) return null;

    const views = [];

    models.forEach((model) => {
      const view = groupToCheckboxViewModel(model, groups);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`UserMapper - groupsToCheckboxViewModel = ${error}`);
  }
};

export const modelToFilter = (model) => {
  try {
    if (!model) return null;

    let view = '';

    model.DefaultFiltered.forEach((filter) => {
      const id = filter.id.toFirstLowerCase();

      if (id.includes('email') && filter.value) {
        view += `${id}=${filter.value}&`;
      } else if (id.includes('name') && filter.value) {
        view += `${id}=${filter.value}&`;
      } else if (id.includes('uuid') && filter.value) {
        view += `${id}=${filter.value}&`;
      } else if (id.includes('document') && filter.value) {
        view += `${id}=${filter.value.replace(/\D/g, '')}&`;
      } else if (id.includes('type') && filter.value) {
        view += `${id}=${filter.value}&`;
      } else if (id.includes('status') && filter.value && filter.value !== 'all') {
        view += `${id}=${filter.value}&`;
      } else if (id.includes('departmentName') && filter.value) {
        view += `department=${filter.value}&`;
      }
    });

    view += `page=${model.Page + 1}&`;
    view += `quantity=${model.PageSize}&`;
    view += `type=${model.type.toUpperCase()}`;

    return view;
  } catch (error) {
    throw new Error(`UserMapper - modelToFilter = ${error}`);
  }
};

export const permissionModelToViewModel = (model) => {
  try {
    if (!model || !model.data || !model.data.grantedAuthority.length) {
      return {
        data: [],
        error: true
      };
    }

    return {
      data: model.data.grantedAuthority.map((perm) => perm.authority),
      error: false
    };
  } catch (error) {
    throw new Error(`UserMapper - permissionModelToViewModel = ${error}`);
  }
};
