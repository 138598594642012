import { LIST_ORDER_PAYMENT, LIST_ORDER } from '../actionTypes/order';

const BASE = {
  state: false,
  data: null
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: []
  }
};

const INITIAL_STATE = {
  listOrderPayment: BASE,
  listOrder: BASE_PAGINATION
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_ORDER_PAYMENT:
      return { ...state, listOrderPayment: action.payload };
    case LIST_ORDER:
      return { ...state, listOrder: action.payload };
    default:
      return state;
  }
};
