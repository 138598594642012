import { LIST_EXTRACT } from '../actionTypes/costumerTransaction';

const BASE = {
  state: false,
  data: null
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: []
  }
};

const INITIAL_STATE = {
  stateCustomerTransaction: BASE,
  listCustomerTransaction: BASE_PAGINATION
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_EXTRACT:
      return { ...state, listCustomerTransaction: action.payload };
    default:
      return state;
  }
};
