import { POST_PAYMENT, LIST_PAYMENT } from '../actionTypes/payment';

const BASE = {
  state: false,
  data: null
};

const INITIAL_STATE = {
  statePostPayment: BASE,
  listPayment: BASE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_PAYMENT:
      return { ...state, statePostPayment: action.payload };
    case LIST_PAYMENT:
      return { ...state, listPayment: action.payload };
    default:
      return state;
  }
};
