import {
  TRANSACTION_INDICATORS,
  FUTURE_PAYMENTS,
  TRANSACTION_ABSTRACT,
  RECEIVABLES,
  LIST_BALANCE,
  LIST_DASHBOARD,
  STORES_BY_FILTER
} from '../actionTypes/dashboard';
import dashboardStateModel from '../model/dashboard';

const BASE = {
  state: false,
  data: null
};

const BASE_TRANSACTION_INDICATORS = {
  state: false,
  data: { ...dashboardStateModel.transactionIndicators }
};

const BASE_FUTURE_PAYMENTS = {
  state: false,
  data: { ...dashboardStateModel.futurePayments }
};

const BASE_TRANSACTION_ABSTRACT = {
  state: false,
  data: { ...dashboardStateModel.transactionAbstract }
};

const BASE_RECEIVABLES = {
  state: false,
  data: { ...dashboardStateModel.receivables }
};

const BASE_STORES_BY_FILTER = {
  state: true,
  data: { ...dashboardStateModel.filter }.searchStores,
  isLoading: false
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: []
  }
};

const INITIAL_STATE = {
  listBalance: BASE,
  listDashboard: BASE_PAGINATION,
  transactionIndicators: BASE_TRANSACTION_INDICATORS,
  futurePayments: BASE_FUTURE_PAYMENTS,
  transactionAbstract: BASE_TRANSACTION_ABSTRACT,
  receivables: BASE_RECEIVABLES,
  storesByFitler: BASE_STORES_BY_FILTER
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TRANSACTION_INDICATORS:
      return { ...state, transactionIndicators: action.payload };
    case FUTURE_PAYMENTS:
      return { ...state, futurePayments: action.payload };
    case TRANSACTION_ABSTRACT:
      return { ...state, transactionAbstract: action.payload };
    case RECEIVABLES:
      return { ...state, receivables: action.payload };
    case LIST_BALANCE:
      return { ...state, listBalance: action.payload };
    case LIST_DASHBOARD:
      return { ...state, listDashboard: action.payload };
    case STORES_BY_FILTER:
      return { ...state, storesByFitler: action.payload };
    default:
      return state;
  }
};
