import { logout } from '../service/user';
import notify from '../mapper/notify';
import showToast from './showToast';

export default (data) => {
  const base = {};

  if (!data) return base;

  if (data.message === 'Network Error') {
    const message = 'Serviço indisponível no momento, tente novamente mais tarde';
    showToast(message, 'error');
    return base;
  }

  if (data.message === 'Request failed with status code 401' || data.status === 401) {
    const message =
      data.data.cause && data.data.cause.errors
        ? notify(data.data.cause.errors[0].error, data.data.cause.errors[0].message)
        : 'Usuário ou senha inválido';
    showToast(message, 'error');
    base.state = false;
    base.data = null;
    logout();
    return base;
  }

  if (data.status === 500) {
    const message =
      data.data.cause && data.data.cause.errors
        ? notify(data.data.cause.errors[0].error, data.data.cause.errors[0].message)
        : 'Falha no servidor';
    showToast(message, 'error');
    base.state = false;
    base.data = null;
    return base;
  }

  if (data.status >= 400 && data.status < 500) {
    const message =
      data.data.cause && data.data.cause.errors
        ? notify(data.data.cause.errors[0].error, data.data.cause.errors[0].message)
        : 'Erro interno';
    showToast(message, 'error');
    base.state = false;
    base.data = null;
    base.status = data.status;
    return base;
  }

  if (data.status === 204) {
    const response = data;
    response.data = true;
    return response;
  }

  return data;
};
