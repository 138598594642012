import { toast } from 'react-toastify';

let messageList = [];

function verifyExistToastMessage(message) {
  if (messageList.indexOf(message) >= 0) return true;
  return false;
}

function removeToastMessage(message) {
  messageList = messageList.filter((msgOfList) => message !== msgOfList);
}

function showToast(message, type) {
  if (!verifyExistToastMessage(message)) {
    messageList.push(message);
    toast[type](message, { onClose: () => removeToastMessage(message) });
  }
}

export default showToast;
