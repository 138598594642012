export default {
  PERMISSIONS: {
    ADMIN: 'ADMIN',
    DASHBOARD: 'EAI_BO_DASHBOARD',
    STORES_VIEW: 'EAI_BO_STORES_VIEW',
    STORES_PAYMENTS: 'EAI_BO_STORES_PAYMENTS',
    STORES_PAYMENTS_SCHEDULE: 'EAI_BO_STORES_PAYMENTS_SCHEDULE',
    STORES_PAYMENTS_RESUME: 'EAI_BO_STORES_PAYMENTS_RESUME',
    USERS: 'EAI_BO_USERS',
    USERS_STORE: 'EAI_BO_STORES_USERS',
    CATEGORIES: 'EAI_BO_CATEGORIES',
    PERMISSIONS: 'EAI_BO_PERMISSIONS',
    GROUPS: 'EAI_BO_GROUPS',
    PAYMENT_METHODS: 'EAI_BO_PAYMENT_METHODS',
    BANKS: 'EAI_BO_BANKS',
    OPTIN: 'EAI_BO_OPTIN',
    CUSTOMERS: 'EAI_BO_CUSTOMERS',
    CUSTOMERS_BLOCK_UNBLOCK_ACCOUNT_CLOSED: 'EAI_BO_BLOCK_UNBLOCK_ACCOUNT_CLOSED',
    CUSTOMERS_BLOCK_UNBLOCK_CUSTOMER_SOLICITATION: 'EAI_BO_BLOCK_UNBLOCK_CUSTOMER_SOLICITATION',
    CUSTOMERS_BLOCK_UNBLOCK_FRAUD_SUSPECT: 'EAI_BO_BLOCK_UNBLOCK_FRAUD_SUSPECT',
    CUSTOMERS_BLOCK_UNBLOCK_CONFIRMED_FRAUD: 'EAI_BO_BLOCK_UNBLOCK_CONFIRMED_FRAUD',
    CUSTOMERS_BLOCK_UNBLOCK_COMPLIANCE: 'EAI_BO_COMPLIANCE',
    CUSTOMERS_BLOCK_UNBLOCK_PREVENCAO_FRAUDE: 'EAI_BO_PREVENCAO_FRAUDE',
    CUSTOMERS_BLOCK_UNBLOCK_CUSTOMER_HERO: 'EAI_BO_CUSTOMER_HERO',
    ORDERS: 'EAI_BO_ORDERS',
    MANUAL_PAYMENT: 'EAI_BO_STORES_PAYMENTS_MANUAL_PAYMENT',
    BUSINESS_PARAMETER: 'EAI_BO_BUSINESS_PARAMETER',
    STORES_CREATE_EDIT_BENEFITS: 'EAI_BO_STORES_CREATE_EDIT_BENEFITS',
    STORES_CREATE: 'EAI_BO_STORES_CREATE',
    STORES_EDIT: 'EAI_BO_STORES_EDIT',
    STORES_EDIT_BANK_ACCOUNT: 'EAI_BO_STORES_EDIT_BANK_ACCOUNT',
    STORES_ACTIVE: 'EAI_BO_STORES_ACTIVATE',
    EAI_BO_STORE_UPLOAD: 'EAI_BO_STORE_UPLOAD',
    EAI_BO_STORE_UPLOAD_UPDATE: 'EAI_BO_STORE_UPLOAD_UPDATE',
    REPORTS: 'EAI_BO_REPORTS',
    GRANT_BALANCE_PJ: 'EAI_BO_STORES_GRANT_BALANCE',
    GRANT_BALANCE_PF: 'EAI_BO_CUSTOMERS_GRANT_BALANCE',
    STORES_CREATE_EDIT: 'EAI_BO_STORES_CREATE_EDIT',
    EAI_BO_PROCESS_SPLIT: 'EAI_BO_PROCESS_SPLIT',
    EAI_BO_REPROCESS_SPLIT: 'EAI_BO_REPROCESS_SPLIT',
    EAI_BO_PROCESS_TED: 'EAI_BO_PROCESS_TED',
    EAI_BO_FINANCIAL_UPLOAD_NF: 'EAI_BO_FINANCIAL_UPLOAD_NF',
    EAI_BO_CASHOUT: 'EAI_BO_CASHOUT',
    EAI_FRAUD_PREVENTION: 'EAI_FRAUD_PREVENTION',
    EAI_FRAUD_PREVENTION_TRUSTED: 'EAI_FRAUD_PREVENTION_TRUSTED',
    EAI_FRAUD_PREVENTION_BLOCKLIST_PF: 'EAI_FRAUD_PREVENTION_BLOCKLIST_PF',
    EAI_FRAUD_PREVENTION_BLOCKLIST_PJ: 'EAI_FRAUD_PREVENTION_BLOCKLIST_PJ',
    EAI_BO_CANCELLATION_OF_ORDERS: 'EAI_BO_CANCELLATION_OF_ORDERS',
    EAI_BO_FACIAL_BIOMETRICS: 'EAI_BO_FACIAL_BIOMETRICS',
    DELETE_CUSTOMER_PIX_KEY: 'DELETE_CUSTOMER_PIX_KEY',
    EAI_BO_CAMPAIGN: 'EAI_BO_CAMPAIGN',
    EAI_BO_ACTIONS_LIST: 'EAI_BO_ACTIONS_LIST',
    CNAB: 'EAI_BO_CNAB'
  }
};
