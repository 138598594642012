import { LIST_STORE_TYPE } from '../actionTypes/storeType';

const BASE = {
  state: false,
  data: null
};

const INITIAL_STATE = {
  listStoreType: BASE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_STORE_TYPE:
      return { ...state, listStoreType: action.payload };
    default:
      return state;
  }
};
