import { LIST_CONTABIL, UPLOAD_CONTABIL, EXPORT_CONTABIL } from '../actionTypes/lancamentoContabil';

const BASE = {
  state: false,
  msg: '',
  data: null
};

const INITIAL_STATE = {
  listContabil: BASE,
  uploadContabil: BASE,
  exportContabil: BASE
};

export default (state = INITIAL_STATE, action) => {
  const { state: loading, msg } = action.payload || {};

  switch (action.type) {
    case LIST_CONTABIL:
      return { ...state, listContabil: action.payload };
    case UPLOAD_CONTABIL:
      return {
        ...state,
        listContabil: { ...state.listContabil, state: loading, msg }
      };
    case EXPORT_CONTABIL:
      return {
        ...state,
        listContabil: { ...state.listContabil, state: loading, msg }
      };
    default:
      return state;
  }
};
