import { POST_OPTIN, LIST_OPTIN } from '../actionTypes/optin';

const BASE = {
  state: false,
  data: null
};

const INITIAL_STATE = {
  statePostOptin: BASE,
  listOptin: BASE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_OPTIN:
      return { ...state, statePostOptin: action.payload };
    case LIST_OPTIN:
      return { ...state, listOptin: action.payload };
    default:
      return state;
  }
};
