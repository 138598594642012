import './middlewares/polyfill';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch, HashRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ReactTableDefaults } from 'react-table-6';
import { unregister } from './middlewares/registerServiceWorker';
import createStore from './store/store';
import DefaultLayout from './containers';
import Page404 from './views/pages/page404';
import Page500 from './views/pages/page500';
import PrivateRoute from './views/utils/privateRoute';
import TableDefaults from './config/table/table';
import Config from './config/environments';
import 'moment/locale/pt-br';

import 'react-table-6/react-table.css';
import 'flag-icon-css/css/flag-icon.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './scss/style.css';
import './scss/componentDatepicker/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';


import { setSessionFlagByURLParam } from './lib/session-flag';

const store = createStore(window.__INITIAL_STATE__);

setSessionFlagByURLParam();

Object.assign(ReactTableDefaults, TableDefaults);

const AppWithRouter = () => {
  useEffect(() => {
    console.log('Current version:', Config.APP_VERSION);
  }, []);

  return (
    <Provider store={store}>
      <div>
        <ToastContainer autoClose={8000} />
        <HashRouter>
          <Switch>
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <PrivateRoute path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </HashRouter>
      </div>
    </Provider>
  );
};

ReactDOM.render(<AppWithRouter />, document.getElementById('root'));
unregister();
