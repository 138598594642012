import {
  POST_STORE,
  LIST_STORE,
  GET_STORE,
  ORBITALL_ACCOUNT,
  LIST_ENRICH,
  LIST_UPLOAD_REPORT
} from '../actionTypes/store';

const BASE = {
  state: false,
  data: null
};

const INITIAL_STATE = {
  statePostStore: BASE,
  listStore: BASE,
  storeById: BASE,
  createAccount: BASE,
  listUploadReport: BASE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_STORE:
      return { ...state, statePostStore: action.payload };
    case LIST_STORE:
      return { ...state, listStore: action.payload, totalStore: action.total };
    case GET_STORE:
      return { ...state, storeById: action.payload };
    case ORBITALL_ACCOUNT:
      return { ...state, createAccount: action.payload };
    case LIST_ENRICH:
      return { ...state, listEnrich: action.payload.data };
    case LIST_UPLOAD_REPORT:
      return { ...state, listUploadReport: action.payload };
    default:
      return state;
  }
};
