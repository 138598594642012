export default (message, error) => {
  try {
    if (!message && !error) return null;

    let err = error;

    err = err.includes('customer.not.found') ? 'Cliente não encontrado' : err;
    err = err.includes('store.not.found') ? 'Loja não encontrada' : err;
    err = err.includes('unable.to.retrieve.balance') ? 'Falha ao recuperar saldo na WireCard' : err;
    err = err.includes('unable.to.retrieve.customer.balance')
      ? 'Falha ao recuperar saldo do cliente'
      : err;
    err = err.includes('UNABLE_TO_RETRIEVE_BALANCE')
      ? 'Não foi possível consultar o saldo dessa loja'
      : err;
    // err = err.includes('INTERNAL_ERROR') ? 'Erro interno inesperado' : err;
    // err = err.includes('ONLY_NUMBER') ? 'Somente números aceitos' : err;

    return err;
  } catch (err) {
    return message;
  }
};
