import moment from 'moment';
import weekUtil from '../lib/weekUtil';

const dashboardStateModel = {
  Uuid: '',
  FantasyName: '',
  pagination: {
    quantity: [5, 10, 20, 50, 100],
    count: 0,
    size: 10
  },
  filter: {
    searchStores: [],
    selectedStores: []
  },
  transactionIndicators: {
    count: 0,
    total: 0,
    average: 0
  },
  transactionAbstract: {
    total: 0,
    authorized: 0,
    canceled: 0,
    others: 0
  },
  futurePayments: {
    list: Array(5)
      .fill(null)
      .map((_, i) => ({
        date: moment(new Date()).add(i, 'M').format('YYYY-MM'),
        netValue: 0
      }))
  },
  receivables: {
    list: Array(5)
      .fill(null)
      .map((_, i) => ({
        date: moment(weekUtil().startDate).add(i, 'days').format('YYYY-MM-DD'),
        netValue: 0,
        semaphore: 'NONE',
        isToday:
          moment(weekUtil().startDate).add(i, 'days').format('YYYY-MM-DD') ===
          moment(new Date()).format('YYYY-MM-DD')
      }))
  },
  balanceAvailable: null,
  futureBalance: null,
  blockedBalance: null
};

export default dashboardStateModel;
