import { toast } from 'react-toastify';
import { axiosInstance as axios, axiosLogin } from '../lib/axios';
import Config from '../config/environments';
import CONST_USER from '../const/user';
import { viewModelToModel, viewsModelToModels, permissionModelToViewModel } from '../mapper/user';
import {
  AUTH_USER,
  LIST_USER,
  POST_USER,
  PUT_USER,
  PUT_USER_STATUS,
  GET_USER_PERMISSIONS,
  PUT_RESEND_PASSWORD
} from '../actionTypes/user';
import { updateState } from '.';

export const getDataUser = (data, state) => async (dispatch) => {
  const base = {
    type: LIST_USER,
    payload: {}
  };
  base.state = true;

  const url = `${Config.USER.URL}?${data}`;

  axios
    .get(url)
    .then((response) => {
      base.payload = updateState(response);
      base.payload.data = viewsModelToModels(base.payload.data, state);
    })
    .catch((error) => {
      base.payload = error;
    })
    .finally(() => {
      dispatch(base);
    });

  dispatch(base);
};

export const authDataUser = (data) => async (dispatch) => {
  const base = {
    type: AUTH_USER,
    state: true,
    payload: {}
  };

  const url = Config.USER.AUTH;

  axiosLogin
    .post(url, data)
    .then((response) => {
      base.payload = updateState(response);
      base.payload.data = viewModelToModel(base.payload.data);
    })
    .catch((error) => {
      base.payload = error;
    })
    .finally(() => {
      dispatch(base);
      if (base.payload.data) window.location.reload();
    });

  dispatch(base);
};

export const setStatusAuth = (data) => async (dispatch) => {
  const base = {
    type: AUTH_USER,
    payload: { data, state: false }
  };

  dispatch(base);
};

export const postDataUser = (data, isExternalDepartment) => async (dispatch) => {
  const base = {
    type: POST_USER,
    payload: {}
  };

  base.state = true;

  const url = Config.USER.BASE;

  axios
    .post(url, data)
    .then((response) => {
      base.payload = updateState(response);
      if (response?.status === 200) {
        toast.success(CONST_USER.FORM.NOTIFY_SUCCESS_CAD);
        isExternalDepartment
          ? toast.warn(CONST_USER.FORM.DEPARTMENTS.TOAST_EXTERNAL)
          : toast.warn(CONST_USER.FORM.DEPARTMENTS.TOAST_INTERNAL);
      }
    })
    .catch((error) => {
      base.payload = error;
    })
    .finally(() => {
      dispatch(base);
    });

  dispatch(base);
};

export const putDataUserPortal = (props, state) => async (dispatch) => {
  const payload = {
    uuid: state.uuid,
    email: state.email,
    name: state.name,
    type: state.type,
    password: undefined,
    document: state.document,
    groups: props.map((group) => {
      return {
        id: group.id,
        permissions: group.permissions.map((permission) => ({ id: permission.id }))
      };
    })
  };

  const base = {
    type: POST_USER,
    payload: {}
  };

  base.state = true;

  const url = `${Config.USER.PORTAL}/${payload.uuid}`;

  axios
    .put(url, payload)
    .then((response) => {
      if (response?.status === 200) toast.success(CONST_USER.FORM.NOTIFY_SUCCESS_UPD);
      base.payload = updateState(response);
    })
    .catch((error) => {
      base.payload = error;
    })
    .finally(() => {
      dispatch(base);
    });

  dispatch(base);
};

export const putDataUser = (data) => async (dispatch) => {
  const base = {
    type: PUT_USER,
    payload: {}
  };

  base.state = true;

  const url = `${Config.USER.BACKOFFICE}/${data.uuid}`;

  axios
    .put(url, data)
    .then((response) => {
      base.payload = updateState(response);
      if (response?.status === 200) toast.success(CONST_USER.FORM.NOTIFY_SUCCESS_UPD);
    })
    .catch((error) => {
      base.payload = error;
    })
    .finally(() => {
      dispatch(base);
    });

  dispatch(base);
};

export const putStatusUser = (uuid) => async (dispatch) => {
  const base = {
    type: PUT_USER_STATUS,
    payload: {}
  };
  base.state = true;
  const url = `${Config.USER.CHANGE_STATUS}/${uuid}`;
  axios
    .put(url)
    .then((response) => {
      base.payload = updateState(response);
      if (response?.status === 200) {
        toast.success(
          response?.data?.status === 'ACTIVE'
            ? CONST_USER.FORM.NOTIFY_SUCCESS_ACTIVE_USER
            : CONST_USER.FORM.NOTIFY_SUCCESS_INACTIVE_USER
        );
      }
    })
    .catch((error) => {
      base.payload = error;
    })
    .finally(() => {
      dispatch(base);
    });
  dispatch(base);
};

export const getUserPermissions = () => async (dispatch) => {
  const base = {
    type: GET_USER_PERMISSIONS,
    payload: {
      data: [],
      error: false
    }
  };
  base.state = true;

  const url = Config.USER.PERMISSIONS;
  axios
    .get(url)
    .then((response) => {
      base.payload = permissionModelToViewModel(updateState(response));
    })
    .catch((error) => {
      base.payload.data = error;
      base.payload.error = true;
    })
    .finally(() => {
      dispatch(base);
    });

  dispatch(base);
};

export const putResendPassword = (uuid, recoveryMethod) => async (dispatch) => {
  const base = {
    type: PUT_RESEND_PASSWORD,
    payload: {}
  };
  base.state = true;
  const url = `${Config.USER.BASE}/${uuid}${Config.USER.RESEND_PASSWORD}`;
  axios
    .put(url)
    .then((response) => {
      base.payload = updateState(response);
      if (response.status === 200) {
        toast.success(CONST_USER.FORM.RESEND_PASSWORD.TOAST[recoveryMethod].SUCCESS);
      }
    })
    .catch((error) => {
      base.payload = error;
    })
    .finally(() => {
      dispatch(base);
    });
  dispatch(base);
};
