import { POST_CATEGORY, LIST_CATEGORY } from '../actionTypes/category';

const BASE = {
  state: false,
  data: null
};

const INITIAL_STATE = {
  statePostCategory: BASE,
  listCategory: BASE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_CATEGORY:
      return { ...state, statePostCategory: action.payload };
    case LIST_CATEGORY:
      return { ...state, listCategory: action.payload };
    default:
      return state;
  }
};
