import { deleteAllCookies, localSet } from '../lib/session';

export const updateLastAPICallTime = () =>
  localSet('LAST_API_CALL', new Date().getTime().toString());

export const logout = async () => {
  deleteAllCookies();
};

export function hasPermission(permissionType, permissionList) {
  if (permissionType && permissionList.find((perm) => perm === permissionType)) return true;

  return false;
}
