import { combineReducers } from 'redux';
import UserReducer from './userReducer';
import CategoryReducer from './categoryReducer';
import PartnerReducer from './partnerReducer';
import PermissionReducer from './permissionReducer';
import InvoiceReducer from './invoiceReducer';
import VouchersReducer from './vouchersReducer';
import ContabilReducer from './lancamentoContabilReducer';
import GroupReducer from './groupReducer';
import PaymentReducer from './paymentReducer';
import PaymentOptionReducer from './paymentOptionReducer';
import BankReducer from './bankReducer';
import OptinReducer from './optinReducer';
import ScheduleReducer from './scheduleReducer';
import TransferReducer from './transferReducer';
import ReceivableReducer from './receivableReducer';
import StoreReducer from './storeReducer';
import StoreTypeReducer from './storeTypeReducer';
import ShoppingReducer from './shoppingReducer';
import CustomerReducer from './customerReducer';
import CustomerTransactionReducer from './customerTransactionReducer';
import OrderReducer from './orderReducer';
import DashboardReducer from './dashboardReducer';
import BenefitReducer from './benefitReducer';
import SalesChannelReducer from './salesChannelReducer';

export const reducers = (asyncReducers) =>
  combineReducers({
    userState: UserReducer,
    categoryState: CategoryReducer,
    invoiceState: InvoiceReducer,
    vouchersState: VouchersReducer,
    contabilState: ContabilReducer,
    permissionState: PermissionReducer,
    groupState: GroupReducer,
    paymentState: PaymentReducer,
    paymentOptionState: PaymentOptionReducer,
    bankState: BankReducer,
    optinState: OptinReducer,
    scheduleState: ScheduleReducer,
    transferState: TransferReducer,
    receivableState: ReceivableReducer,
    storeState: StoreReducer,
    storeTypeState: StoreTypeReducer,
    partnerState: PartnerReducer,
    shoppingState: ShoppingReducer,
    customerState: CustomerReducer,
    customerTransactionState: CustomerTransactionReducer,
    orderState: OrderReducer,
    dashboardState: DashboardReducer,
    benefitState: BenefitReducer,
    salesChannelState: SalesChannelReducer,
    ...asyncReducers
  });
