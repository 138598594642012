import {
  LIST_VOUCHERS,
  UPLOAD_VOUCHERS,
  EXPORT_VOUCHERS,
  CREATE_AN8
} from '../actionTypes/vouchers';

const BASE = {
  state: false,
  msg: '',
  data: null
};

const INITIAL_STATE = {
  listVOUCHERS: BASE,
  uploadVOUCHERS: BASE,
  exportVOUCHERS: BASE
};

export default (state = INITIAL_STATE, action) => {
  const { state: loading, msg } = action.payload || {};

  switch (action.type) {
    case LIST_VOUCHERS:
      return { ...state, listVouchers: action.payload };
    case UPLOAD_VOUCHERS:
      return {
        ...state,
        listVouchers: { ...state.listVouchers, state: loading, msg }
      };
    case EXPORT_VOUCHERS:
      return {
        ...state,
        listVouchers: { ...state.listVouchers, state: loading, msg }
      };
    case CREATE_AN8:
      return {
        ...state,
        listVouchers: { ...state.listVouchers, state: loading, msg }
      };
    default:
      return state;
  }
};
