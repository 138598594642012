import { LIST_INVOICE, UPLOAD_INVOICE, EXPORT_INVOICE, CREATE_AN8 } from '../actionTypes/invoice';

const BASE = {
  state: false,
  msg: '',
  data: null
};

const INITIAL_STATE = {
  listInvoice: BASE,
  uploadInvoice: BASE,
  exportInvoice: BASE
};

export default (state = INITIAL_STATE, action) => {
  const { state: loading, msg } = action.payload || {};

  switch (action.type) {
    case LIST_INVOICE:
      return { ...state, listInvoice: action.payload };
    case UPLOAD_INVOICE:
      return {
        ...state,
        listInvoice: { ...state.listInvoice, state: loading, msg }
      };
    case EXPORT_INVOICE:
      return {
        ...state,
        listInvoice: { ...state.listInvoice, state: loading, msg }
      };
    case CREATE_AN8:
      return {
        ...state,
        listInvoice: { ...state.listInvoice, state: loading, msg }
      };
    default:
      return state;
  }
};
