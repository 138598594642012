import { POST_GROUP, LIST_GROUP_AND_DEPARTMENTS } from '../actionTypes/group';

const BASE = {
  state: false,
  data: null
};

const INITIAL_STATE = {
  statePostGroup: BASE,
  listGroupAndDepartments: BASE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_GROUP:
      return { ...state, statePostGroup: action.payload };
    case LIST_GROUP_AND_DEPARTMENTS:
      return { ...state, listGroupAndDepartments: action.payload };
    default:
      return state;
  }
};
