import Config from '../config/environments/index';

export const sessionGet = (key) => {
  const stringValue = window.sessionStorage.getItem(key);
  if (stringValue !== null) {
    const value = JSON.parse(stringValue);
    const expirationDate = new Date(value.expirationDate);
    if (expirationDate > new Date()) {
      return value.value;
    }
    window.sessionStorage.removeItem(key);
  }
  return null;
};

export const sessionSet = (key, value, expiration = 0) => {
  const expirationDate = new Date(new Date().getTime() + expiration);
  const newValue = {
    value,
    expirationDate: expirationDate.toISOString()
  };
  window.sessionStorage.setItem(key, JSON.stringify(newValue));
};

export const sessionRemove = (key) => {
  window.sessionStorage.removeItem(key);
};

export const localGet = (key) => {
  const stringValue = window.localStorage.getItem(key);
  if (stringValue !== null) {
    const value = JSON.parse(stringValue);
    return value.value;
  }

  window.localStorage.removeItem(key);
  return null;
};

export const localSet = (key, value) => {
  const newValue = {
    value
  };
  window.localStorage.setItem(key, JSON.stringify(newValue));
};

export const localRemove = (key) => {
  window.localStorage.removeItem(key);
};

export function setCookie(name, value, exMinutes) {
  const date = new Date();
  date.setTime(date.getTime() + exMinutes * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

export function getCookie(name) {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieList = decodedCookie.split(';');
  const rgx = new RegExp(`${name}=`, 'ig');
  const cookieFound = cookieList.find((ck) => ck.match(rgx));
  return cookieFound ? cookieFound.replace(`${name}=`, '').trimStart().trimEnd() : null;
}

export function getParsedCookie(name) {
  try {
    const rawCookie = getCookie(name);
    return JSON.parse(rawCookie);
  } catch (e) {
    return null;
  }
}

export function deleteCookie(name) {
  if (typeof window !== 'undefined') {
    const date = new Date();

    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

    document.cookie = `${name}=;domain=${
      Config.BASE_WEB_DOMAIN
    };expires=${date.toUTCString()};path=/`;
  }
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=delete;domain=${Config.BASE_WEB_DOMAIN};expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}
