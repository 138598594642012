/* eslint-disable func-names */
/* eslint-disable no-extend-native */
import moment from 'moment';
import { minMaxRange } from './utils';

export const DocumentFormat = (document) => {
  let value = document.replace(/[^\d]/g, '');

  if (!value) return '-';

  if (value.length <= 11) {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  value = value.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5');

  return value;
};

export const CpfMask = (value) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

export const CnpjMask = (value) => {
  let v = value;
  v = v.replace(/\D/g, '');
  v = v.replace(/^(\d{2})(\d)/, '$1.$2');
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
  v = v.replace(/(\d{4})(\d)/, '$1-$2');

  return v;
};

export const DocumentMask = (value) => {
  let v = value;
  v = v.replace(/\D/g, '');

  if (v.length <= 11) {
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    v = v.replace(/^(\d{2})(\d)/, '$1.$2');
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
    v = v.replace(/(\d{4})(\d)/, '$1-$2');
  }

  return v;
};

export const PhoneCompleteMask = (value) => {
  let v = value;
  v = v.replace(/\D/g, '');

  if (v.length <= 10) {
    v = v.replace(/(\d{0})(\d)/, '($1$2');
    v = v.replace(/(\d{2})(\d)/, '$1) $2');
    v = v.replace(/(\d{4})(\d)/, '$1-$2');
  } else {
    v = v.replace(/(\d{0})(\d)/, '($1$2');
    v = v.replace(/(\d{2})(\d)/, '$1) $2');
    v = v.replace(/(\d{5})(\d)/, '$1-$2');
  }

  return v;
};

export const brlFormatterNative = new Intl.NumberFormat('pt-Br', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2
});

export const brlFormatter = {
  format: (value) => brlFormatterNative.format(value / 100)
};

export function BarCodeFormatter(barcode) {
  if (!barcode) return '-';

  return barcode.replace(
    /^(\d{5})(\d{5})?(\d{5})?(\d{6})?(\d{5})?(\d{6})?(\d)?(\d{14})/,
    '$1 . $2 $3 . $4  $5 . $6  $7  $8'
  );
}

export function formatCPF(document) {
  return document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

export function formatCNPJ(document) {
  return document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
}

export function formatCEP(document) {
  return document.replace(/(\d{5})/, '$1-');
}


export function formatPhone(document) {
  if (document.length === 8) {
    return document.replace(/(\d{4})/, '$1-');
  }
  return document.replace(/(\d{5})/, '$1-');
}

export function mask(number, mask) {
  number = number.replace(/[^0-9]/g, '');
  let numberPos = 0;
  return number
    .split('')
    .map((num, i) => {
      if (mask[i + numberPos] === '0') {
        return num;
      }
      return mask[i + numberPos++] + num;
    })
    .join('');
}

export function dateMask(string) {
  let value = string.substring(0, 10);

  switch (value.length) {
    case 1:
      if (value > 3) {
        value = '3';
      }
      break;
    case 2:
      if (value > 31) {
        value = '31';
      }
      break;
    case 3:
    case 4:
      if (value[2] !== '/') {
        value = value.substr(0, 2) + '/' + value[2];
      }
      if (value[3] > 1) {
        value = value.substr(0, 3) + '1';
      }
      break;
    case 5:
      if (value.substr(3, 2) > 12) {
        value = value.substr(0, 3) + '12';
      }
      break;
    case 6:
    case 7:
      if (value[5] !== '/') {
        value = value.substr(0, 5) + '/' + value[5];
      }
      if (value[6] < 1) {
        value = value.substr(0, 6) + '1';
      }
      break;
    default:
      break;
  }

  return value;
}

export function capitalizeText(string) {
  const result = string.split(' ').map((value) => {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  });

  return result.join(' ');
}

// eslint-disable-next-line no-extend-native
String.prototype.nameMask = function () {
  return this.replace(/[^A-Za-zÀ-Úà-ú\s]/g, '');
};

// eslint-disable-next-line no-extend-native
String.prototype.cpfMask = function () {
  return mask(this, '000.000.000-00');
};

// eslint-disable-next-line no-extend-native
String.prototype.cnpjMask = function () {
  return mask(this, '00.000.000/0000-00');
};

// eslint-disable-next-line no-extend-native
String.prototype.siebelNumberMask = function () {
  const masked = mask(this, '0-0000000000');
  return masked.length < 3 ? '1-' : masked;
};

export function dateIsValid(date) {
  const dateRegex = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/;
  return dateRegex.test(date);
}

export function mailEaiIsValid(email) {
  const dateRegex = /(\W|^)[\w.-]{0,82}@(ipiranga|ultra|e-ai)\.(com|com\.br|ipiranga)(\W|$)/;
  return dateRegex.test(email);
}

// eslint-disable-next-line no-extend-native
String.prototype.dateMask = function () {
  const dateSplit = this.split(/-/);
  let day = dateSplit[2];
  let month = dateSplit[1];
  let year = dateSplit[0];

  if (day && day.length === 2) {
    const max = moment().endOf('month');

    const nDay = minMaxRange(parseInt(day), 1, max);
    day = nDay < 10 ? `0${nDay}` : nDay;
  }

  if (month && month.length === 2) {
    const nMonth = minMaxRange(parseInt(month), 1, 12);
    month = nMonth < 10 ? `0${nMonth}` : nMonth;
  }

  if (year && year.length === 4) {
    const min = new Date().getFullYear() - 120;
    const max = new Date().getFullYear() - 18;
    year = minMaxRange(parseInt(year), min, max);
  }

  return mask(`${day}${month}${year}`, '00/00/0000');
};

// eslint-disable-next-line no-extend-native
String.prototype.zipcodeMask = function () {
  return mask(this, '00000-000');
};

// eslint-disable-next-line no-extend-native
String.prototype.phoneMask = function () {
  const cleanPhone = this.replace(/[^0-9]/g, '');
  return cleanPhone.length <= 8 ? mask(this, '0000-0000') : mask(this, '00000-0000');
};

// eslint-disable-next-line no-extend-native
Number.prototype.BRLCurrency = function () {
  return brlFormatterNative.format(this);
};

// eslint-disable-next-line no-extend-native
Number.prototype.BRLCurrencyWhenInt = function () {
  return brlFormatter.format(this);
};

// eslint-disable-next-line no-extend-native
String.prototype.toDateLocale = function () {
  return moment(this).format('DD/MM/YYYY');
};

// eslint-disable-next-line no-extend-native
String.prototype.toDateTimeLocale = function () {
  return moment(this).format('DD/MM/YYYY HH:mm:ss');
};

// eslint-disable-next-line no-extend-native
String.prototype.toTimeLocale = function () {
  return moment(this).format('HH:mm:ss');
};

// eslint-disable-next-line no-extend-native
String.prototype.toFirstUpperCase = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

// eslint-disable-next-line no-extend-native
String.prototype.toFirstLowerCase = function () {
  return this.charAt(0).toLowerCase() + this.slice(1);
};

// FORM Validate
function validatingFieldForm(str = '', regex) {
  const lastString = str.substring(str.length - 1);

  if (regex.test(lastString)) return str;
  return str.substring(0, str.length - 1);
}

String.prototype.addressField = function () {
  const regex = /^[A-Za-z0-9-.áàâãéèêíóôõúçÁÀÂÃÉÈÊÍÓÔÕÚÇ ]+$/;
  return validatingFieldForm(this, regex);
};

String.prototype.onlyNumbersField = function () {
  const regex = /^[0-9]+$/;
  return validatingFieldForm(this, regex);
};

String.prototype.onlyLettersField = function () {
  const regex = /^[A-Za-z]+$/;
  return validatingFieldForm(this, regex);
};
