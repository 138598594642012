import { LIST_SALES_CHANNEL } from '../actionTypes/salesChannel';

const BASE = {
  state: false,
  data: null
};

const INITIAL_STATE = {
  list: BASE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_SALES_CHANNEL:
      return { ...state, list: action.payload };
    default:
      return state;
  }
};
