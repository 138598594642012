import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setStatusAuth } from '../../actions/user';

class Permission extends Component {
  constructor(props) {
    super(props);

    toast.error('Usuário sem permissão.');
  }

  render() {
    return <Redirect to={{ pathname: '/home' }} />;
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setStatusAuth
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  authUser: state.userState.authUser
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Permission));
