import { LIST_PARTNER } from '../actionTypes/partner';

const BASE = {
  state: false,
  data: null
};

const INITIAL_STATE = {
  listPartner: BASE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_PARTNER:
      return { ...state, listPartner: action.payload };
    default:
      return state;
  }
};
