import { POST_BENEFIT, LIST_BENEFIT, GET_BENEFIT, STATUS_BENEFIT } from '../actionTypes/benefit';

const BASE = {
  state: false,
  data: null
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: []
  }
};

const INITIAL_STATE = {
  statePostBenefit: BASE,
  listBenefit: BASE_PAGINATION,
  benefitByStoreUuid: BASE,
  stateStatusBenefit: BASE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_BENEFIT:
      return { ...state, statePostBenefit: action.payload };
    case LIST_BENEFIT:
      return { ...state, listBenefit: action.payload };
    case GET_BENEFIT:
      return { ...state, benefitByStoreUuid: action.payload };
    case STATUS_BENEFIT:
      return { ...state, stateStatusBenefit: action.payload };
    default:
      return state;
  }
};
