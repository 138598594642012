export function minMaxRange(value, min, max) {
  if (value > max) return max;
  if (value < min) return min;
  return value;
}

export function parseDate(date, config = {}) {
  const { withHours, slash } = config;
  const dateOnlyformat = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  };
  const dateValidRegex = /^(\d{4})-(\d{2})-(\d{2})$/;

  let formatted = date.match(dateValidRegex) ? `${date}T03:24:00` : date;

  formatted = new Date(formatted).toLocaleString('pt-BR', withHours ? {} : dateOnlyformat);

  return slash ? formatted : formatted.replaceAll('/', '-');
}

export function removeUrlTrailingSlash(urlString) {
  return urlString.replace(/\/+$/, '');
}
