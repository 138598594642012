import { POST_PAYMENT_OPTION, LIST_PAYMENT_OPTION } from '../actionTypes/paymentOption';

const BASE = {
  state: false,
  data: null
};

const INITIAL_STATE = {
  statePostPaymentOption: BASE,
  listPaymentOption: BASE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_PAYMENT_OPTION:
      return { ...state, statePostPaymentOption: action.payload };
    case LIST_PAYMENT_OPTION:
      return { ...state, listPaymentOption: action.payload };
    default:
      return state;
  }
};
